import React from 'react'
import './index.css'
import Hr from '../Hr'
import Arrow from '../Arrow'

function MainFooter() {
  return (
    <div className='w-4/5 mx-auto' id='Contact'>
        <h1 className="text-5xl font-medium mb-10">
            Contact
        </h1>
        <Hr backgroundColor = 'footer-red' classes="mb-16"></Hr>
        <div className="flex flex-wrap w-full content-center justify-between mb-20">
            <Arrow link = "mailto:mmpl.me1@gmail.com" extlink={true} content="Send Me an Email" arrowColor = "#CC0000" classes="mb-10 md:mb-0"></Arrow>
            <div className="flex flex-wrap gap-5">
                <Arrow link = "https://www.linkedin.com/in/mazahar-pasha-mmpl-2b77931a5" extlink={true} content="LinkedIn" arrowColor = "#0077B7" classes="text-[#0077B7]"></Arrow>
                <Arrow link = "https://www.instagram.com/mr_mmpl/" extlink={true} content="Instagram" arrowColor = "#DA2E78" classes="md:ml-16 make-transparent instagram bg-clip-text"></Arrow>
                <Arrow link = "https://dribbble.com/mr_mmpl" extlink={true} content="Dribbble" arrowColor = "#F26798" classes="md:ml-16 font-medium make-transparent bg-[#F26798]"></Arrow>
            </div>
        </div>
    </div>
  )
}

export default MainFooter