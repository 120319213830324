import React from "react";
import img1 from "../../../images/Notes Bookshlf.png";
import img3 from "../../../images/Bookshlf Design.png";
import img2 from "../../../images/multiScreenBookshlf.png";
import Hr from "../../Hr";
import Footer from "../Footer/Footer";
import "./bookshlf.css";

export default function Bookshlf() {
  return (
    <>
      <div className="flex justify-center">
        <div className="bookshlf-container">
          <h1 className="text-[65px] text-[#F4A946] font-semibold">Bookshlf</h1>
          <h3 className="text-[34px] text-[#F4A946] mt-2">
            A Online Platform To Buy and Sell Used Books
          </h3>
          <h4>
            we were approached by a startup owner looking to revamp the UI for
            his website. The client was looking to modernize and update the
            image of the website, which had remained unchanged for 2 years.
          </h4>
          <img src={img1} className="w-full" alt="Sorry" srcset="" />
          <h4>
            We began the project by conducting market research and analyzing the
            target audience for the website. We found that the website's current
            UI did not effectively appeal to the younger and students
            demographic that the client was hoping to attract.
          </h4>
          <h4>
            Based on our research, we designed a new UI concept that
            incorporated bold, vibrant colors and a sleek, modern design. We
            presented several options to the client and worked with his to
            refine the final design.
          </h4>
          <img src={img2} className="w-full" alt="Sorry" srcset="" />

          <h4>
            Once the UI was finalized, The client was thrilled with the end
            result.
          </h4>
          <h4>
            Overall, this project was a great success and we were able to
            effectively help the client modernize and refresh his website's
            image to better appeal to his target audience.
          </h4>
          <img src={img3} className="w-full mb-[234px]" alt="Sorry" srcset="" />
        </div>
      </div>
      <Hr backgroundColor = "dark-red" classes="w-4/5"></Hr>
      <Footer />
    </>
  );
}
