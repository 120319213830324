import React from "react";
import './index.css'

function GridHeading(props) {
  return (
    <div>
      <h1 className="main-heading leading-[4rem] mb-5" style={{color:props.textColor}}>
        {props.mainHeading}
      </h1>
      <h3 className="sub-heading">
        {props.subHeading}
      </h3>
    </div>
  );
}

export default GridHeading;
