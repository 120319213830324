import React from "react";
import img1 from "../../../images/Notes Filti.png";
import img2 from "../../../images/Filit Design.png";
import img3 from "../../../images/multiScreenFilit.png";
import Hr from "../../Hr";
import Footer from "../Footer/Footer";
import "./filit.css";

export default function Filit() {
  return (
    <>
      <div className="flex justify-center">
        <div className="filit-container">
          <h1 className="text-[65px] text-[#4A8BE1] font-semibold">Filit</h1>
          <h3 className="text-[34px] text-[#4A8BE1] mt-2">A Fintech startup</h3>
          <h4>
            My latest design project was for a fintech startup, looking to
            launch it's micro-investing app, aimed at revolutionising the
            finance industry.
          </h4>
          <h4>
            {" "}
            We began the project by identifying the target audience, conducting
            appropriate market research and analyzing the competition in the
            fintech space
          </h4>
          <img src={img1} className="w-full" alt="Sorry" srcset="" />
          <h4>
            Based on this research, we decided on suitable brand guidelines,
            which would help us reach and connect with out target audience.{" "}
          </h4>
          <h4>
            We then worked towards creating a modern, minimalistic user
            interface, using colors and elements that conveyed stability and
            trust.{" "}
          </h4>
          <img src={img2} className="w-full" alt="Sorry" srcset="" />

          <h4>
            Subsequently, we worked extensively on improving the user-experience
            by incorporating a smooth on boarding process and a fluid user-flow.{" "}
          </h4>
          <h4>
            As a part of the core team, I iterated on the design until I came up
            with a final version that met all of requirements.
          </h4>
          <h4>
            We followed the brand guidelines and the UI/UX laws, ensuring that
            the app's design was consistent across all touchpoints.
          </h4>
          <img src={img3} className="w-full mb-[234px]" alt="Sorry" srcset="" />
        </div>
      </div>
      <Hr backgroundColor = "dark-red" classes="w-4/5"></Hr>
      <Footer />
    </>
  );
}
