import React from "react";
import './index.css'

function DisplayGrid(props) {
  return (
    <div className="container">
      <div className="Heading">{props.Heading}</div>
      <div className="Content">{props.Content}</div>
      <div className="Bottom">{props.Bottom}</div>
    </div>
  );
}

export default DisplayGrid;