import React from 'react';
import './index.css';
import Arrow from '../Arrow';
import resume from '../../images/MMPL Resume.pdf'
import MainFooter from '../MainFooter';

export default function About() {
  return (
    <>
        <div className="flex justify-center mt-[100px]" id='About'>
          <div className="about-container ">
            <div className="about-content mb-[29px]">
              <h1 className='text-8xl mb-[10%]'>About</h1>
              <h2>I'm MMPL, I am a UI UX and Graphic Designer with a marketing background. </h2>
              <h4>As a UI/UX and graphic designer with a background in marketing, I bring a unique perspective to my design work. My understanding of marketing principles helps me to create visually appealing and user-friendly designs that effectively communicate the value of a product or service to the target audience. Whether it's through website design, app development, or branding and graphic design, I strive to create designs that are not only aesthetically pleasing, but also functional and intuitive for the user."</h4>
              <Arrow link={resume} content="CHECK MY RESUME" arrowColor="#D03434" classes="text-sm text-[#D03434] mt-3 font-semibold" arrowClasses="h-4"></Arrow>
              {/* <a href={resume} className="ext-link flex h-10 text-sm text-[#D03434] mt-3 font-semibold" target='_blank'>
                CHECK MY RESUME
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-5 h-4">
                  <path
                    d="M7.15 6.95l9.8.1.1 9.8-1.95.01-.01-6.44-8 7.99-1.38-1.38 8.12-8.12-6.69-.01V6.95z"
                    fillRule="evenodd"
                    fill="h-4"
                  />
                </svg>
              </a> */}
            </div>
            <div className="other">
              <h3>What I like</h3>
              <ul>
                <li>Drawing and Painting</li>
                <li><a href='https://www.instagram.com/pixel.0001/' target='_blank'>Photography</a></li>
                <li>Managing People</li>
              </ul>
              <h3>What I'm learning</h3>
              <ul>
                <li>Web Development</li>
                <li>Digital Art</li>
                <li>Digital Marketing </li>
                <li>Video Editing</li>
              </ul>
            </div>
          </div>
        </div>
        <MainFooter></MainFooter>


    </>
  )
}
