import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Navbar from './components/Navbar'
import Hero from './components/Hero'
import Hr from './components/Hr'
import GridOne from './components/GridOne';
import GridTwo from './components/GridTwo';
import GridThree from './components/GridThree';
import reportWebVitals from './reportWebVitals';
import About from './components/About/About';
import MainFooter from './components/MainFooter';
import Filit from './components/Works/Filit/Filit';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Bookshlf from './components/Works/Bookshlf/Bookshlf';
import ScrollToTop from './components/ScrollToTop';
import Gallery from './components/Works/Graphic Gallery/Gallery';
import Intro from './components/Intro/Intro';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop/>
      {/* <Intro></Intro> */}
      <Navbar></Navbar>
      <Routes>
        <Route exact path='/' element={
          <>
          <Hero></Hero>
          <Hr backgroundColor = "dark-red" classes="w-4/5 h-[1px]"></Hr>
          <div id="Work">
            <GridOne></GridOne>
            <Hr backgroundColor = "dark-red" classes="w-4/5 h-[1px]"></Hr>
            <GridTwo></GridTwo>
            <Hr backgroundColor = "dark-red" classes="w-4/5 h-[1px]"></Hr>
            <GridThree></GridThree>
            {/* <Hr backgroundColor = "dark-red" classes="w-4/5 h-[1px]"></Hr> */}
          </div>
          {/* <About/> */}
          <MainFooter></MainFooter>
          </>
        }/>
        <Route path='/filit' element={<Filit/>}/>
        <Route path='/bookshlf' element={<Bookshlf/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/Graphics' element={<Gallery/>}/>
        {/* <Route path='/contact' element={<h1>Contact</h1>} />   */}
      </Routes>  
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
