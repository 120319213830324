import React from "react";
import DisplayGrid from "../DisplayGrid";
import gridImage from "../../images/Graphic Gallery.png";
import GridHeading from "../GridHeading";
import GridLink from "../GridLink";

function GridThree() {
  var heading = (
    <GridHeading
      mainHeading="Graphic Gallery"
      textColor="#6EEF54"
      subHeading="SOME OF MY DESIGN WORKS"></GridHeading>
  );
  var content = (
    <div>
      <p>
      Here you will get some marketing materials such as brochures, business cards, logos for various clients
      </p>
      <GridLink
        linkAddress="/Graphics"
        content="View Designs"
        color="#6EEF54"></GridLink>
    </div>
  );
  var bottom = <img src={gridImage} alt="" />;
  return (
    <DisplayGrid
      Heading={heading}
      Content={content}
      Bottom={bottom}></DisplayGrid>
  );
}

export default GridThree;
