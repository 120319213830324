import React from "react";

function Hr(props) {
  //bg-dark-red bg-footer-red 
  return (
    <hr className={"h-0.5 m-auto border-0 bg-" + props.backgroundColor + " " + props.classes}></hr>
  );
}

export default Hr;
