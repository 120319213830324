import React from "react";
import DisplayGrid from "../DisplayGrid";
import gridImage from "../../images/Bookshlf Design.png";
import GridHeading from "../GridHeading";
import GridLink from "../GridLink";

function GridOne() {
  var heading = (
    <GridHeading
      mainHeading="Bookshlf"
      textColor="#FEC45D"
      subHeading="Buy and Sell Used Books  "></GridHeading>
  );
  var content = (
    <div>
      <p>
      We were approached by a startup owner looking to revamp the UI for his website.
      </p>
      <GridLink
        linkAddress="/bookshlf"
        content="View Project"
        color="#F4A946"></GridLink>
    </div>
  );
  var bottom = <img src={gridImage} alt="" />;
  return (
    <DisplayGrid
      Heading={heading}
      Content={content}
      Bottom={bottom}></DisplayGrid>
  );
}

export default GridOne;
