import React from 'react'
import { Nav, NavLink } from 'react-router-dom'
import Arrow from '../../Arrow'

export default function Footer() {
  return (
    
    <footer className='mt-[64px] mb-[64px] flex justify-center'>
        <div className='text-[22px] w-[80%]' >
            <h2 className='md:text-[50px] font-medium mb-[30px] text-[34px]'>Other Works</h2>
            <ul className='flex flex-col'>
                <NavLink to='/filit' className={({ isActive }) => (isActive ? 'hidden' : 'mt-[15px] text-[#4A8BE1] flex')}>
                  Filit 
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={"h-7 "}>
                    <path
                      d="M7.15 6.95l9.8.1.1 9.8-1.95.01-.01-6.44-8 7.99-1.38-1.38 8.12-8.12-6.69-.01V6.95z"
                      fillRule="evenodd"
                      fill='#4A8BE1'
                    />
                  </svg>
                </NavLink>
                
                <NavLink to='/Graphics' className={({ isActive }) => (isActive ? 'hidden' : 'mt-[15px] text-[#6EEF54] flex')}>
                  Graphic Gallery 
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={"h-7 "}>
                    <path
                      d="M7.15 6.95l9.8.1.1 9.8-1.95.01-.01-6.44-8 7.99-1.38-1.38 8.12-8.12-6.69-.01V6.95z"
                      fillRule="evenodd"
                      fill='#6EEF54'
                    />
                  </svg>
                </NavLink>
                <NavLink to='/bookshlf' className={({ isActive }) => (isActive ? 'hidden' : 'mt-[15px] text-[#FEC45D] flex')}>
                  Bookshlf 
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={"h-7 "}>
                    <path
                      d="M7.15 6.95l9.8.1.1 9.8-1.95.01-.01-6.44-8 7.99-1.38-1.38 8.12-8.12-6.69-.01V6.95z"
                      fillRule="evenodd"
                      fill='#FEC45D'
                    />
                  </svg>
                </NavLink>
            </ul>
        </div>
    </footer>
  )
}
