import React from "react";
import "./index.css";
import heroLogo from "../../images/Hero Logo.svg";
import Arrow from "../Arrow"

export default function Hero() {
  return (
    <div className="hero-container" id="Hero">
      <div className="hero-section">
        <img src={heroLogo} alt="" />
        <div className="hero-section-content flex flex-col justify-center align-top">
          <h1 className="hero-section-heading text-7xl font-semibold text-mmpl-red indent-[-5px]">
            Hello!
          </h1>
          <p className="hero-section-text font-medium text-3xl mt-5">
            I'm MMPL, a UI UX and Graphic Designer with a marketing background. 
          </p>
          {/* <Arrow link="" content="CHECK MY RESUME" arrowColor="#D03434" classes="text-sm text-[#D03434] mt-3 font-semibold" arrowClasses="h-4"></Arrow> */}
        </div>
      </div>
    </div>
  );
}
