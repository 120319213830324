import React from "react";
import DisplayGrid from "../DisplayGrid";
import gridImage from "../../images/Filit Design.png";
import GridHeading from "../GridHeading";
import GridLink from "../GridLink";

function GridTwo() {
  var heading = (
    <GridHeading
      mainHeading="Filit"
      textColor="#529CFF"
      subHeading="FINTECH APPLICATION "></GridHeading>
  );
  var content = (
    <div>
      <p>
      My latest design project was for a fintech startup,  looking to launch it's micro-investing app, aimed at revolutionising the finance industry. 
      </p>
      <GridLink
        linkAddress="/filit"
        content="View Project"
        color="#529CFF"></GridLink>
    </div>
  );
  var bottom = <img src={gridImage} alt="" className="w-full" />;
  return (
    <DisplayGrid
      Heading={heading}
      Content={content}
      Bottom={bottom}></DisplayGrid>
  );
}

export default GridTwo;
