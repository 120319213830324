import React from 'react'
import Hr from '../../Hr'
import Footer from '../Footer/Footer'
import GalleryImage from '../../../images/Graphic Gallery More.png'

export default function Gallery() {
  return (
    <>
    <img src={GalleryImage} alt="" className='py-40 mx-auto w-[80%]'/>
    <Hr backgroundColor = "dark-red" classes="w-4/5"></Hr>
    <Footer />
    </>
  )
}
