import React from "react";
import { Link } from "react-router-dom";

function GridLink(props) {
  return (
    <Link
      to={props.linkAddress}
      className="GridLinkContainer flex column content-center my-10 cursor-pointer">
      <p className="GridLinkContent pr-5">{props.content}</p>
      <svg
        width="25"
        height="20"
        className="my-auto"
        viewBox="0 0 25 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.49292 10H23.4929"
          stroke={props.color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.4929 1L23.4929 10L14.4929 19"
          stroke={props.color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Link>
  );
}

export default GridLink;
