import React from "react";
import "./index.css";
import { Link } from 'react-router-dom'

const Arrow = (props) => {
  if(props.extlink){
    return(
      <a href={props.link} target="_blank" className={"ext-link flex h-10 " + props.classes}>
      {props.content}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={"h-5 " + props.arrowClasses}>
        <path
          d="M7.15 6.95l9.8.1.1 9.8-1.95.01-.01-6.44-8 7.99-1.38-1.38 8.12-8.12-6.69-.01V6.95z"
          fillRule="evenodd"
          fill={props.arrowColor}
        />
      </svg>
    </a>
    );
  }
  return (
    <Link to={props.link} target="_blank" className={"ext-link flex h-10 " + props.classes}>
      {props.content}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={"h-5 " + props.arrowClasses}>
        <path
          d="M7.15 6.95l9.8.1.1 9.8-1.95.01-.01-6.44-8 7.99-1.38-1.38 8.12-8.12-6.69-.01V6.95z"
          fillRule="evenodd"
          fill={props.arrowColor}
        />
      </svg>
    </Link>
  );
};
Arrow.defaultProps = {
  link: "",
  classes: "",
  arrowClasses: "",
  extlink:false
}

export default Arrow;
