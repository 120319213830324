import React , {useRef} from 'react'
import navbarLogo from "../../images/Navbar Logo.svg";
import { NavLink } from "react-router-dom";
import {Link as Slink} from "react-scroll";


import "./index.css";

export default function Navbar() {

  const NavCont=useRef(null);
  // NavCont.current.style.top='-80px';
  
  setTimeout(()=>{
    NavCont.current.style.top='0px';
  },500);

  function menutoggle(){
    document.getElementById('hamburger-button').classList.toggle('activehamburger');
    document.getElementById('navdiv').classList.toggle('activemobnav');
  }
  return (
    <div className="navbar-container" ref={NavCont}>
      <nav className="navbar">
        <NavLink to='/'><img src={navbarLogo} alt="" /></NavLink>
        <div className="hamburger-button" id="hamburger-button" onClick={menutoggle}>
          <span className="line-1"></span>
          <span className="line-2"></span>
          <span className="line-3"></span>
        </div>
        <div className="navbar-links-container" id="navdiv">
            <Slink to="Hero" spy={true} smooth={true} offset={-50} duration={500} className="navbar-link" activeClass="activenavlink" onClick={menutoggle}>
              <NavLink to="/" className="w-full">
                  Home
              </NavLink >
            </Slink>
            <Slink to="Work" spy={true} smooth={true} offset={-80} duration={500} className="navbar-link" activeClass="activenavlink" onClick={menutoggle}>
              <NavLink to="/" className="w-[100vh]">
                  Work
              </NavLink>
            </Slink>
            <Slink to="About" spy={true} smooth={true} offset={-80} duration={500} className="navbar-link" activeClass="activenavlink" onClick={menutoggle}>
              <NavLink to="/about " className="w-[100vh]">
                  About
              </NavLink>
            </Slink>
            <Slink to="Contact" spy={true} smooth={true} offset={0} duration={500} className="navbar-link" activeClass="activenavlink" onClick={menutoggle}>
              <NavLink to="/" className="w-full">
                  Contact
              </NavLink>
            </Slink>
        </div>
      </nav>
    </div>
  );
}
